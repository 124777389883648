import React, { useState } from "react";
import { useLaravelReactI18n } from "laravel-react-i18n";
import { Link } from "@inertiajs/react";

export default function CaseBlock({ data, locale }) {
    const { t } = useLaravelReactI18n();

    return (
        <div className={"case-card"}>
            {
                data.slug &&
                <Link href={`/${locale}/cases/${data.slug}`} className="case-card__link">&nbsp;</Link>
            }
            <div className={"case-card__preview"}>
                <img
                    src={`/storage/${data.image}`}
                    alt="card-preview"
                />
            </div>
            <div className={"case-card__content"}>
                {
                    data.slug ?
                        <div className={"case-card__top"}>
                            <div className={"case-card__tag"}>{data.category}</div>
                            <div className={"case-card__link-icon"}>
                                <img src={require('@images/pages/cases/icon-arrow-diagonal.svg').default} alt="go to case page" />
                            </div>
                        </div> :
                        <div className={"case-card__tag"}>{data.category}</div>
                }

                <div className={"case-card__title"}>{data.name}</div>

                <div className={"case-card__group"}>
                    <p className={"case-card__group-title"}>
                        {t("site.casesBlock.groupMainChallenge")}
                    </p>
                    <p
                        className={
                            "case-card__group-desc case-card__group-desc--purple"
                        }
                        dangerouslySetInnerHTML={{
                            __html: data.objective,
                        }}
                    ></p>
                </div>

                {
                    data.takeaways ?
                        <>
                            <div className={"case-card__group"}>
                                <p className={"case-card__group-title"}>
                                    {t("site.casesBlock.groupWinningFormula")}
                                </p>
                                <p
                                    className={
                                        "case-card__group-desc"
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: data.solution,
                                    }}
                                ></p>
                            </div>
                            <div className={"case-card__row"}>
                                <div className={"case-card__group"}>
                                    <p className={"case-card__group-title"}>
                                        {t("site.casesBlock.groupAchievements")}
                                    </p>
                                    <p
                                        className={"case-card__group-desc"}
                                        dangerouslySetInnerHTML={{
                                            __html: data.result,
                                        }}
                                    ></p>
                                </div>

                                <div className={"case-card__group"}>
                                    <p className={"case-card__group-title"}>
                                        {t("site.casesBlock.groupTakeaways")}
                                    </p>
                                    <p
                                        className={"case-card__group-desc"}
                                        dangerouslySetInnerHTML={{
                                            __html: data.takeaways,
                                        }}
                                    ></p>
                                </div>
                            </div>
                        </> :
                        <div className={"case-card__row"}>
                            <div className={"case-card__group"}>
                                <p className={"case-card__group-title"}>
                                    {t("site.casesBlock.groupWinningFormula")}
                                </p>
                                <p
                                    className={"case-card__group-desc"}
                                    dangerouslySetInnerHTML={{
                                        __html: data.solution,
                                    }}
                                ></p>
                            </div>

                            <div className={"case-card__group"}>
                                <p className={"case-card__group-title"}>
                                    {t("site.casesBlock.groupAchievements")}
                                </p>
                                <p
                                    className={"case-card__group-desc"}
                                    dangerouslySetInnerHTML={{
                                        __html: data.result,
                                    }}
                                ></p>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}
